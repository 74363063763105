import type { PaletteMode } from '@mui/material';
import { CssBaseline, ThemeProvider as MuiThemeProvider, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Head from 'next/head';
import { useMemo } from 'react';
import { darkTheme, lightTheme } from './themes';

// Theme Merger
export const theme = lightTheme;
export const themeAtom = atomWithStorage( 'theme', 'default' );

// useTheme Provider
export default function ThemeProvider( { children } ) {
	const theme = useAtomValue( themeAtom );
	const dark = useMediaQuery( '(prefers-color-scheme: dark)' );
	
	const mode: PaletteMode = useMemo( () => {
		switch ( theme ) {
			case 'light':
			case 'dark':
				return theme;
			default:
				return dark ? 'dark' : 'light';
		}
	}, [ theme, dark ] );
	
	return (
		<MuiThemeProvider theme={mode === 'dark' ? darkTheme : lightTheme}>
			<Head>
				<meta key='theme-color' name='theme-color' content={mode === 'dark' ? '#010409' : '#F7FAFC'}/>
			</Head>
			<CssBaseline/>
			{children}
		</MuiThemeProvider>
	);
}

