'use client';
import { createContext, useContext, useEffect, useState } from 'react';
import SplashLoading from './splashLoading';

type C = { setText: ( text: string ) => void, open: ( value?: boolean ) => void };
const SplashContext = createContext<C>( { setText: () => null, open: () => null } );
SplashContext.displayName = 'Splash';

export default function SplashProvider( { children } ) {
	const [ loading, setLoading ] = useState( true );
	const [ text, setText ] = useState( 'Please Wait' );
	
	return (
		<SplashContext.Provider value={{ open: ( value = false ) => setLoading( value ), setText }}>
			<SplashLoading loading={loading} text={text}/>
			{children}
		</SplashContext.Provider>
	);
}

interface SplashProps {
	value?: boolean
}

export const useSplash = () => useContext( SplashContext );

let splashTimeout: NodeJS.Timer | null = null;

export const useAutoSplash = ( loading: boolean, delay: number = 500 ) => {
	const { open } = useSplash();
	useEffect( () => {
		if ( splashTimeout ) clearTimeout( splashTimeout );
		if ( loading ) {
			splashTimeout = null;
			open( true );
		} else {
			splashTimeout = setTimeout( () => open( false ), delay );
		}
		
		splashTimeout = loading ? null : setTimeout( () => open( false ), delay );
		if ( loading ) open( true );
	}, [ loading ] );
};

export function CompleteSplash( { value = false }: SplashProps ) {
	const { open } = useSplash();
	
	useEffect( () => {
		open( value );
	}, [] );
	
	return null;
}
